import React, { useState, useEffect } from 'react';
import { Col, FormGroup, Button } from 'reactstrap';
import { useFormikContext } from 'formik';
import { Input } from '../../form';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import ConfirmationModal from './confirmation_modal';


const PasswordChange = ({history,data,type}) => {
  const [showModal, setShowModal] = useState(false);
  const [confirmChanges, setConfirmChanges] = useState(false);
  const [editForm,setEditForm] = useState(false);
  const { touched,values, setValues, isSubmitting, handleSubmit } = useFormikContext();

  
  const confirmationModal = <ConfirmationModal showModal={showModal} handleSubmit={handleSubmit}
  closeModal={()=>{setShowModal(false); setConfirmChanges(false)}} 
  confirmChanges={()=>{setShowModal(false); setConfirmChanges(true);}}
  header={"Change password"}
  message={"Are you sure you want to change password?"}/>

  useEffect(()=>{
      setValues({
          ...values,
          email : data?.email,
          id : data?.id
      })
  },[data?.email,data?.id])

  const primaryContactDetails = (
    <FormGroup row className="px-4 pt-4 pb-1">
        <Col lg={12} className = "mt-2">
          <Input
            label="Email id"
            id="email"
            placeholder={data?.email || "Email id"}
            disabled={true}
          />
        </Col>
          <Col lg={12} className="mt-2">
          <Input
            label="Enter new password"
            id="password"
            type='password'
            placeholder="Enter Password"
          />
        </Col>
        <Col lg={12} className = "mt-2">
          <Input
            label="Confirm Password"
            id="password1"
            type='password'
            placeholder="Confirm Password"
          />
        </Col>
        <Col lg={12} className = "mt-2">
        <Input type="textarea" id="remarks" label="Remarks" placeholder="Type here..." maxLength={255}/>
        </Col>
    </FormGroup>
  );

  return (
    <>
      {primaryContactDetails}
      <div className="mt-2 mb-2 px-4 d-flex justify-content-between">
            <Button color="cancel" type="button" onClick={() => history.push('/')}>
              Cancel
            </Button>
            <Button type="button" color="primary" disabled={isSubmitting} onClick={()=>setShowModal(true)}>
              Submit
            </Button>
        </div>
          {confirmationModal}
    </>
  );
};

PasswordChange.propTypes = {
  data : PropTypes.any,
  type : PropTypes.string,
}

export default withRouter(PasswordChange);
