import { FETCH_USER_LIST, FETCH_USER_LIST_SUCCESS , FETCH_USER_LIST_FAILED,
    FETCH_CUSTOMER_LIST, FETCH_CUSTOMER_LIST_SUCCESS, FETCH_CUSTOMER_LIST_FAILED } from "../actions/types"
const initialState = {
    pending: false,
    success: null,
    error: null,
    loading: true,
    data: [],
    otherInfo: {},
}

export default function(state = initialState, action){
    const{type, payload}=action;
    switch(type){
        case FETCH_USER_LIST:
        case FETCH_CUSTOMER_LIST:
            return ({
                ...state,
                pending : true,
                success : null,
                error : null,
                data : null
            })

        case FETCH_USER_LIST_SUCCESS:
        case FETCH_CUSTOMER_LIST_SUCCESS:
            const { list, ...rest } = payload;
            return ({
                ...state,
                pending : false,
                success : true,
                error : null,
                data : list || null,
                otherInfo : rest || {}
            })

        case FETCH_USER_LIST_FAILED:
        case FETCH_CUSTOMER_LIST_FAILED:
            return({
                pending : false,
                success : false,
                error : payload.error,
                data : null
            })
        
        default:
            return state
    }
}