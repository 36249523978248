/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { CardBody, Card, Container, Form, Row, Col } from 'reactstrap';
import { Formik } from 'formik';
import { CustomerForm } from '../../components/tvs-components/update-forms';
 import { custValidate, updateCustomerValidate } from '../../helpers/validate';
import AddressContactRecordPayment from '../../components/tvs-components/update-forms/sidebar';
import { useRouteMatch } from 'react-router';
import { toastr } from 'react-redux-toastr';
import { getCustomerById } from '../../actions/fetchById';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { updateCustomer, updateDues, clearActionStatus, csMapping } from '../../actions/crudActions';
import { getStartupAddressData } from '../../actions/startup';
import styles from 'styled-components';
import { Breadcrumb } from '../../components/elements';
import CsMapping from '../../components/tvs-components/update-forms/csMapping';
import moment from 'moment';
// export const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

const CustomerUpdateForm = ({ match, getCustomerById ,updateCustomer, updateDues,
   customer, crudState, clearActionStatus, getStartupAddressData, csMapping, startup}) => {
  const formikRef = useRef(null);
  const currency = 'PHP';
  const [editableAddress, setEditableAddress] = useState(false);
  const [state, setState] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const toogleAddress = () => setEditableAddress(!editableAddress);

  const route = useRouteMatch();
  const toastrOptions = {
    timeOut: 3000,
    closeOnToastrClick: true,
  };
  const [custLocation, setCustLocation] = useState({
    address: '',
    city: '',
    province: '',
    zipcode: '',
  });

  

  useEffect(() => {
    if (!(startup?.data?.address)) {
      getStartupAddressData();
    }
  }, []);


  useEffect( () => {
     getCustomerById(match?.params?.id);

    customer.pending &&setData(customer.data);
  }, [match?.params?.id]);

  useEffect(()=>{
    setData(customer.data)
  },[customer?.data])
 

  const submit = async (values) => {

    if(values.submitType === "Update Dues"){
      const dues = data?.sales?.dues?.[0]
      const {id,sales_id} = dues;
      const {month_due_date,install_num,penalty_due,rebate,remarks} = values;
      handleDuesUpdate(values,data);
      var expected_amt = 0.0
      if(values?.expected_amt){
      expected_amt = parseFloat(values.expected_amt?.split(',').join(''));}
      
      updateDues({id, sales_id,install_num,month_due_date,expected_amt,penalty_due,rebate,remarks});
      clearActionStatus();
      values.submitType = "";
    }else{
    const {full_name,country_code,phone_number,address,address2,province,zipcode,city} = data
    const acct_number = data?.sales?.acct_number;
    const new_phone_number = values?.phone_number ? values.phone_number : data.phone_number;
    const new_country_code = values?.phone_number ? values.phone_number : data.phone_number;
    const country = values?.country;
    const remarks = values?.remarks;
    updateCustomer({acct_number,full_name,address,address2,province,zipcode,city,country,
      country_code,phone_number,new_phone_number,remarks});
      clearActionStatus();
    }  
  };
  
  const handleDuesUpdate = (values,data) =>{
    const dueInfo = data?.sales?.dues?.[0];
    const sales = data?.sales;
    const dues = data?.sales?.dues;

    setData({
      ...data,
      sales : {
        ...data.sales,
        dues: {
          ...data.sales.dues,
          0:{
            ...dueInfo,
            month_due_date : values.month_due_date || dueInfo.month_due_date,
            install_num : values.install_num || dueInfo.install_num,
            expected_amt : values.expected_amt || dueInfo.expected_amt,
            penalty_due : values.penalty_due || dueInfo.penalty_due,
            rebate : values.rebate || dueInfo.rebate
          }
        }
      }
    })

  }
  function capitalizeTheFirstLetterOfEachWord(words) {
    const separateWord = words.toLowerCase().split(' ');
    for (let i = 0; i < separateWord.length; i++) {
      separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(' ');
  }

  useEffect(() => {
    if (crudState?.initiate) {
      const { pending, success, error, message } = crudState;
      setLoading(pending || false);
      if (success) {
        setEditableAddress(false);
        clearActionStatus();
        toastr.success('', message,toastrOptions);
      } else if (error) {
        toastr.error('Please check the input values again.', error,toastrOptions);
        clearActionStatus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crudState]);

  const handleSaveAddress = (values) => {
    toogleAddress();
    setData({
      ...data,
        address: values?.address || data?.address,
        city: values?.city || data?.city,
        province: values?.province || data?.province,
        zipcode: values?.zipcode || data?.zipcode,
        phone_number: values?.phone_number || data?.phone_number
    });
    const {full_name,country_code,phone_number,country} = data;
    const {address,address2,province,zipcode,city} = values
    const acct_number = data?.sales?.acct_number;
    const new_phone_number = values?.phone_number;
    updateCustomer({acct_number,full_name,address,address2,province,zipcode,city,country,
      country_code,phone_number,new_phone_number});
      clearActionStatus();
    setCustLocation({
        address: values?.address || data?.address,
        city: values?.city || data?.city,
        province: values?.province || data?.province,
        zipcode: values?.zipcode || data?.zipcode,
        phone_number: values?.phone_number || data?.phone_number
    });
  };

    const handleCsMapping = (values) => {
      const users = data?.users;
      const sales = data?.sales;
      const r = data?.sales?.r
      setData({
        ...data,
        client_code : values.client_code,
        sales :{
          ...sales,
          r:{
            ...r,
            client_code : values.client_code,
            users : {
              ...r.users,
              first_name : values.cs_name,
              last_name : "",
              id : values.id,
              client_code : values.client_code,
              phone_number : values.cs_phone_number,
              country_code : values.country_code
            }
          }
        },
        users : {
          ...users,
          first_name : values.cs_name,
          last_name : "",
          id : values.id,
          client_code : values.client_code,
          phone_number : values.cs_phone_number,
          country_code : values.country_code
        }
      })
    const {customer_name,acct_number,cs_name,cs_country_code,cs_phone_number,visit_date,client_code,id,remarks}=values;
    const date = new Date(visit_date);
    csMapping({customer_name,acct_number,cs_name,cs_country_code,cs_phone_number,visit_date,client_code,id,remarks});
    clearActionStatus();
    }

  const customerValidate = (values) => {
    const errors = custValidate(values, startup?.data?.address?.cities);
    return errors;
  };

  return (customer.data &&
    <div className="mt-0">
       <Breadcrumb
        data={[
          {
            label: 'Customer List',
            link: '/getCustomerList',
          },
          {
            label: 'Customer update',
          },
        ]}
      />
      <Container style={{ paddingBottom: '100px' }}>
        <div className="pt-4 pb-2">
          <h1 className="mb-2">Customer Update</h1>
          <H5>Update customer information, update dues and assignments for current month<br/>
          Record last updated by {data?.updatedBy} on {moment(data?.updatedAt).format("DD MMMM YYYY")}</H5>
          
            {/* <Link to='/assignCs'>
                <Button className="btn btn-primary" color="primary">Assign Collection Specialist</Button>
            </Link> */}
        </div>
        <>
          <Row>
          <Col lg={8}>
              <Formik
                initialValues={custLocation}
                enableReinitialize
                onSubmit={handleSaveAddress}
                validate={customerValidate}
              >
                {({ handleSubmit, errors, ...rest }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <AddressContactRecordPayment
                      toogleAddress={toogleAddress}
                      editableAddress={editableAddress}
                      setEditableAddress={setEditableAddress}
                      data={data}
                      setState={setState}
                      setCustLocation={setCustLocation}
                    />
                  </Form>
                )}
              </Formik>
              <Card className="rounded-0">
                <CardBody className="p-0 rounded-0">
                  <Formik
                    innerRef={(instance) => {
                      formikRef.current = instance;
                    }}
                    initialValues={state}
                    enableReinitialize
                    onSubmit={submit}
                    validate={updateCustomerValidate}
                  >
                    {({ handleSubmit, errors, ...rest }) => (
                      <Form onSubmit={handleSubmit} autoComplete="off">
                        <CustomerForm data={data}/>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
            
            {/* <Col lg={8}>
              
            </Col> */}
            <Col lg={4}>
              <Formik
                initialValues={state}
                enableReinitialize
                onSubmit={handleCsMapping}
                validate={customerValidate}
              >
                {({ handleSubmit, errors, ...rest }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <CsMapping
                      toogleAddress={toogleAddress}
                      editableAddress={editableAddress}
                      setEditableAddress={setEditableAddress}
                      data={data}
                      setState={setState}
                      setCustLocation={setCustLocation}
                    />
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </>
      </Container>
    </div>
  );
};

const H5 = styles.h5`
  opacity: 0.8;
`;

CustomerUpdateForm.propTypes = {
  getCustomerById : PropTypes.func.isRequired,
  customer : PropTypes.object.isRequired,
  crudState : PropTypes.object.isRequired,
  clearActionStatus : PropTypes.func.isRequired,
  updateCustomer : PropTypes.object.isRequired,
  updateDues : PropTypes.func.isRequired,
  getStartupAddressData : PropTypes.func.isRequired,
  startup : PropTypes.object,
  csMapping : PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  customer: state.selectedById,
  crudState : state.crudActions,
  startup : state.startup
})

export default connect(mapStateToProps,{getCustomerById,updateCustomer,updateDues,
  clearActionStatus,getStartupAddressData, csMapping})(CustomerUpdateForm);
