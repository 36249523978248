/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import PropTypes from 'prop-types'



const ActivityDropdown = ({ data, handleChange}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [active, setActive] = useState('All');

  const handleSelectItem = (item) => {
    setActive(item);
    handleChange(item);
  };

  const renderItem = (item) => {
    return (
      <DropdownItem key={item} onClick={() => handleSelectItem(item)}>
        {item}
      </DropdownItem>
    );
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} id = "userStatusDropdown">
      <DropdownToggleStyle caret color="x" className="p-0 border-0">
        {active === 'All' ? 'User Status' : active}
      </DropdownToggleStyle>
      <DropdownMenu className="mt-2" id = "userStatusDropdownMenu">
        <DropdownItem key="All" onClick={() => handleSelectItem('All')}>
          All
        </DropdownItem>
        {data.map((item) => renderItem(item))}
      </DropdownMenu>
    </Dropdown>
  );
};

 const DropdownToggleStyle = styled(DropdownToggle)`
  &.btn {
    color: #263238;
    font-size: 20px;
    font-weight: 500;
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
`;
ActivityDropdown.propTypes = {
  handleChange:PropTypes.func,
  data: PropTypes.array.isRequired,
}
export default ActivityDropdown;
