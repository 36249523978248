import axios from "axios";
import {
    FETCH_CUSTOMER_FAILED, FETCH_CUSTOMER, FETCH_CUSTOMER_SUCCESS,
    FETCH_USER, FETCH_USER_FAILED, FETCH_USER_SUCCESS,
    FETCH_CASHIER, FETCH_CASHIER_FAILED, FETCH_CASHIER_SUCCESS,
    FETCH_CS, FETCH_CS_FAILED, FETCH_CS_SUCCESS,
    FETCH_PAYMENT_DETAIL, FETCH_PAYMENT_DETAIL_SUCCESS, FETCH_PAYMENT_DETAIL_FAILED,
    UPDATE_TOKEN
} from "./types";
import { BaseURL } from "../constant";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken"


export const getCustomerById = (id) =>
    async dispatch => {
        // const config={
        //     headers:{
        //         "Content-Type":"application/json"
        //     }
        // }

        dispatch({ type: FETCH_CUSTOMER });
        var res = {}
        try {
            res = await axios.get(BaseURL + '/admin/getCustomerById/' + id);
            dispatch({
                type: FETCH_CUSTOMER_SUCCESS,
                payload: res.data
            });

        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: FETCH_CUSTOMER });
                    var res = {}
                    try {
                        res = await axios.get(BaseURL + '/admin/getCustomerById/' + id);
                        dispatch({
                            type: FETCH_CUSTOMER_SUCCESS,
                            payload: res.data
                        });

                    } catch (err) {
                        dispatch(setAlert(err.response.data.message, 'danger'));
                        dispatch({
                            type: FETCH_CUSTOMER_FAILED,
                            payload: err.response.data.message
                        })
                    }
                } else {
                    dispatch(setAlert(err.response.data.message, 'danger'));
                    dispatch({
                        type: FETCH_CUSTOMER_FAILED,
                        payload: err.response.data.message
                    })
                }
            } else {
                dispatch(setAlert(err.response.data.message, 'danger'));
                dispatch({
                    type: FETCH_CUSTOMER_FAILED,
                    payload: err.response.data.message
                })
            }
        }
    }

export const getUserById = (id) =>
    async dispatch => {
        // const config={
        //     headers:{
        //         "Content-Type":"application/json"
        //     }
        // }

        dispatch({ type: FETCH_USER });
        var res = {}
        try {
            res = await axios.get(BaseURL + '/admin/getUserById/' + id);
            dispatch({
                type: FETCH_USER_SUCCESS,
                payload: res.data
            });

        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: FETCH_USER });
                    var res = {}
                    try {
                        res = await axios.get(BaseURL + '/admin/getUserById/' + id);
                        dispatch({
                            type: FETCH_USER_SUCCESS,
                            payload: res.data
                        });

                    } catch (err) {
                        dispatch(setAlert(err.response.data.message, 'danger'));
                        dispatch({
                            type: FETCH_USER_FAILED,
                            payload: err.response//.data.message
                        })
                    }
                } else {
                    dispatch(setAlert(err.response.data.message, 'danger'));
                    dispatch({
                        type: FETCH_USER_FAILED,
                        payload: err.response//.data.message
                    })
                }
            } else {
                dispatch(setAlert(err.response.data.message, 'danger'));
                dispatch({
                    type: FETCH_USER_FAILED,
                    payload: err.response//.data.message
                })
            }
        }
    }

export const getCashierById = (id, tableState) =>
    async dispatch => {
        // const config={
        //     headers:{
        //         "Content-Type":"application/json"
        //     }
        // }

        dispatch({ type: FETCH_CASHIER });
        var res = {}
        try {
            res = await axios.get(BaseURL + '/admin/getCashierById/' + id,
                {
                    params: {
                        page: tableState.page,
                        sizePerPage: tableState.sizePerPage,
                        month: tableState.month,
                        year: tableState.year
                    }
                });

            dispatch({
                type: FETCH_CASHIER_SUCCESS,
                payload: res.data
            });

        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: FETCH_CASHIER });
                    var res = {}
                    try {
                        res = await axios.get(BaseURL + '/admin/getCashierById/' + id,
                            {
                                params: {
                                    page: tableState.page,
                                    sizePerPage: tableState.sizePerPage,
                                    month: tableState.month,
                                    year: tableState.year
                                }
                            });

                        dispatch({
                            type: FETCH_CASHIER_SUCCESS,
                            payload: res.data
                        });

                    } catch (err) {
                        dispatch(setAlert(err.response.data.message, 'danger'));
                        dispatch({
                            type: FETCH_CASHIER_FAILED,
                            payload: err.response.data.message
                        })
                    }
                } else {
                    dispatch(setAlert(err.response.data.message, 'danger'));
                    dispatch({
                        type: FETCH_CASHIER_FAILED,
                        payload: err.response.data.message
                    })
                }
            } else {
                dispatch(setAlert(err.response.data.message, 'danger'));
                dispatch({
                    type: FETCH_CASHIER_FAILED,
                    payload: err.response.data.message
                })
            }
        }
    }

export const getCSById = (id, tableState) =>
    async dispatch => {
        // const config={
        //     headers:{
        //         "Content-Type":"application/json"
        //     }
        // }

        dispatch({ type: FETCH_CS });
        var res = {}
        try {
            res = await axios.get(BaseURL + '/admin/getCsById/' + id,
                {
                    params: {
                        page: tableState.page,
                        sizePerPage: tableState.sizePerPage,
                        month: tableState.month,
                        year: tableState.year
                    }
                });
            dispatch({
                type: FETCH_CS_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: FETCH_CS });
                    var res = {}
                    try {
                        res = await axios.get(BaseURL + '/admin/getCsById/' + id,
                            {
                                params: {
                                    page: tableState.page,
                                    sizePerPage: tableState.sizePerPage,
                                    month: tableState.month,
                                    year: tableState.year
                                }
                            });
                        dispatch({
                            type: FETCH_CS_SUCCESS,
                            payload: res.data
                        });
                    } catch (err) {
                        dispatch(setAlert(err.response.data.message, 'danger'));
                        dispatch({
                            type: FETCH_CS_FAILED,
                            payload: err.response.data.message
                        })
                    }
                } else {
                    dispatch(setAlert(err.response.data.message, 'danger'));
                    dispatch({
                        type: FETCH_CS_FAILED,
                        payload: err.response.data.message
                    })
                }
            } else {
                dispatch(setAlert(err.response.data.message, 'danger'));
                dispatch({
                    type: FETCH_CS_FAILED,
                    payload: err.response.data.message
                })
            }
        }
    }

export const getPaymentDetailById = (id) =>
    async dispatch => {
        dispatch({ type: FETCH_PAYMENT_DETAIL });
        var res = {}
        try {
            res = await axios.get(BaseURL + '/admin/paymentDetails/' + id)
            dispatch({
                type: FETCH_PAYMENT_DETAIL_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: FETCH_PAYMENT_DETAIL });
                    var res = {}
                    try {
                        res = await axios.get(BaseURL + '/admin/paymentDetails/' + id)
                        dispatch({
                            type: FETCH_PAYMENT_DETAIL_SUCCESS,
                            payload: res.data
                        });
                    } catch (err) {
                        dispatch(setAlert(err.response.data.message, 'danger'));
                        dispatch({
                            type: FETCH_PAYMENT_DETAIL_FAILED,
                            payload: err.response.data.message
                        })
                    }
                } else {
                    dispatch(setAlert(err.response.data.message, 'danger'));
                    dispatch({
                        type: FETCH_PAYMENT_DETAIL_FAILED,
                        payload: err.response.data.message
                    })
                }
            } else {
                dispatch(setAlert(err.response.data.message, 'danger'));
                dispatch({
                    type: FETCH_PAYMENT_DETAIL_FAILED,
                    payload: err.response.data.message
                })
            }
        }
    }
