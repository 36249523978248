import axios from "axios";
import {
    FETCH_CUSTOMER_LIST_FAILED, FETCH_CUSTOMER_LIST, FETCH_CUSTOMER_LIST_SUCCESS,
    FETCH_USER_LIST, FETCH_USER_LIST_FAILED, FETCH_USER_LIST_SUCCESS, UPDATE_TOKEN
} from "./types";

import { setAlert } from "./alert";
import { BaseURL } from "../constant";
import setAuthToken from "../utils/setAuthToken"
import { loadUser } from "./auth";
import { toBeDisabled } from "@testing-library/jest-dom/dist/matchers";

export const getCustomerList = (tableState) =>
    async dispatch => {
        dispatch({
            type: FETCH_CUSTOMER_LIST
        });
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        var req = {};
        req.sizePerPage = tableState.sizePerPage;
        req.page = tableState.page;
        req.search = tableState.filter.search;
        req.sort = tableState.filter.sort;
        const body = JSON.stringify(req);
        var res = {}
        try {
            res = await axios.post(BaseURL + '/admin/getCustomerList', body, config);
            const { rows, ...rest } = res.data
            dispatch({
                type: FETCH_CUSTOMER_LIST_SUCCESS,
                payload: {
                    list: rows,
                    ...rest
                }
            });
            //history.push("/getUserByName");

        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({
                        type: FETCH_USER_LIST,
                    });
                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                    var req = {};
                    req.branch = tableState.filter.branch;
                    req.role = tableState.filter.role;
                    req.sizePerPage = tableState.sizePerPage;
                    req.page = tableState.page;
                    req.search = tableState.filter.search;
                    req.sort = tableState.filter.sort;
                    req.active = tableState.filter.active;
                    const body = req;
                    var res = {}
                    try {
                        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.token;
                        res = await axios.post(BaseURL + '/admin/getCustomerList', body, config);
                        const { rows, ...rest } = res.data
                        dispatch({
                            type: FETCH_CUSTOMER_LIST_SUCCESS,
                            payload: {
                                list: rows,
                                ...rest
                            }
                        });
                    } catch (err) {
                        dispatch(setAlert(err?.response?.data?.message, 'danger'));
                        dispatch({
                            type: FETCH_USER_LIST_FAILED,
                            payload: {
                                error: err?.response?.data?.message
                            }
                        })
                    }
                } else {
                    dispatch(setAlert(err?.response?.data?.message, 'danger'));
                    dispatch({
                        type: FETCH_USER_LIST_FAILED,
                        payload: {
                            error: err?.response?.data?.message
                        }
                    })
                }
            } else {
                dispatch(setAlert(err?.response?.data?.message, 'danger'));
                dispatch({
                    type: FETCH_USER_LIST_FAILED,
                    payload: {
                        error: err?.response?.data?.message
                    }
                })
            }
        }
    }

export const getUserList = (tableState) =>
    async dispatch => {
        dispatch({
            type: FETCH_USER_LIST,
        });
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        var req = {};
        req.branch = tableState.filter.branch;
        req.role = tableState.filter.role;
        req.sizePerPage = tableState.sizePerPage;
        req.page = tableState.page;
        req.search = tableState.filter.search;
        req.sort = tableState.filter.sort;
        req.active = tableState.filter.active;
        const body = req;
        var res = {}
        try {
            res = await axios.post(BaseURL + '/admin/getUserList', body, config);
            const { rows, ...rest } = res.data;
            dispatch({
                type: FETCH_USER_LIST_SUCCESS,
                payload: {
                    list: rows,
                    ...rest
                }
            });
            //history.push("/getUserByName");

        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({
                        type: FETCH_USER_LIST,
                    });
                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                    var req = {};
                    req.branch = tableState.filter.branch;
                    req.role = tableState.filter.role;
                    req.sizePerPage = tableState.sizePerPage;
                    req.page = tableState.page;
                    req.search = tableState.filter.search;
                    req.sort = tableState.filter.sort;
                    req.active = tableState.filter.active;
                    const body = req;
                    var res = {}
                    try {
                        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.token;
                        res = await axios.post(BaseURL + '/admin/getUserList', body, config);
                        const { rows, ...rest } = res.data
                        dispatch({
                            type: FETCH_USER_LIST_SUCCESS,
                            payload: {
                                list: rows,
                                ...rest
                            }
                        });
                    } catch (err) {
                        dispatch(setAlert(err?.response?.data?.message, 'danger'));
                        dispatch({
                            type: FETCH_USER_LIST_FAILED,
                            payload: {
                                error: err?.response?.data?.message
                            }
                        })
                    }
                } else {
                    dispatch(setAlert(err?.response?.data?.message, 'danger'));
                    dispatch({
                        type: FETCH_USER_LIST_FAILED,
                        payload: {
                            error: err?.response?.data?.message
                        }
                    })
                }
            } else {
                dispatch(setAlert(err?.response?.data?.message, 'danger'));
                dispatch({
                    type: FETCH_USER_LIST_FAILED,
                    payload: {
                        error: err?.response?.data?.message
                    }
                })
            }
        }
    }