import React, { useState, useEffect } from 'react';
import { Col, FormGroup, Button } from 'reactstrap';
import { useFormikContext } from 'formik';
import { Input, Select } from '../../form';
import { withRouter } from 'react-router';
import ContactRecord from './contact';
import PropTypes from 'prop-types';
import { ROLES } from '../../../constant';
import ConfirmationModal from './confirmation_modal';
import { getManagerList } from '../../../actions/startup';
import { connect } from 'react-redux';

const UserForm = ({ history, data, type, getManagerList, startup }) => {
  const [showModal, setShowModal] = useState(false);
  const [confirmChanges, setConfirmChanges] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [originalManager, setOriginalManager] = useState("");
  const { touched, values, setValues, isSubmitting, handleSubmit, errors } = useFormikContext();

  const toggleEditForm = () => {
    setEditForm(!editForm);
  }

  const roleMapping = {
    "C": "Corporate User",
    "M": "Manager",
    "A": "Cashier",
    "F": "Collection Specialist"
  }

  useEffect(() => {
    if (type === 'create') {
      setEditForm(true)
    }
  }, [type]);

  useEffect(() => {
    setValues({ ...values, first_name: data?.first_name, last_name: data?.last_name, role: data?.role, email: data?.email, branch: data?.branch, city: data?.city,country_code: data?.country_code, phone_number: data?.phone_number })
  }, [data])

  useEffect(() => {
    getManagerList();
  }, []);

  useEffect(() => {
    const data = (startup?.data?.managerList || []);
    setFetchedData(startup?.data?.managerList || []);
    setManagerList(
      data.map((item) => ({
        label: `${item.branch}  ${item.first_name} ${item.last_name}`,
        value: item.email
      }))
    )

  }, [startup?.data?.managerList])

  useEffect(() => {
    if (data?.manager_id) {
      const managerDetail = fetchedData.filter(m => m.id === data?.manager_id);
      setOriginalManager(managerDetail?.[0]?.first_name + " " + managerDetail?.[0]?.last_name);
    }
  }, [fetchedData, data?.manager_id])

  const confirmationModal = <ConfirmationModal showModal={showModal} handleSubmit={handleSubmit}
    closeModal={() => { setShowModal(false); setConfirmChanges(false) }}
    confirmChanges={() => { setShowModal(false); setConfirmChanges(true); }}
    header={type === 'create' ? "Create User" : ""}
    message={type === 'create' ? "Please check all the details before creating user" : ""} />

  const primaryContactDetails = (
    <>
      <FormGroup row className="px-4 pt-4 pb-1">
        <Col lg={6}>
          {console.log}
          <Input
            label="First Name"
            id="first_name"
            placeholder={data?.first_name || "First Name"}
            noShowError={false}
            disabled={type !== 'create' && !editForm}
          />
        </Col>
        <Col lg={6}>
          <Input
            label="Last Name"
            id="last_name"
            placeholder={data?.last_name || "Last Name"}
            disabled={type !== 'create' && !editForm}
          />
        </Col>

        <Col lg={6} className="mt-2">
          <Input
            label="Email id"
            id="email"
            placeholder={data?.email || "Email id"}
            disabled={type === 'create' ? false : !editForm}
          />
        </Col>
        <Col lg={6} className="mt-2">
          <Select id="role" label="Current Role" options={ROLES}
            disabled={type !== 'create' && !editForm} placeholder={roleMapping[`${data?.role}`]} />
        </Col>

        <Col lg={6} className="mt-2">
          <Input
            label="Branch"
            id="branch"
            placeholder={data?.branch || "Branch"}
            disabled={(type === 'create' ? false : !editForm) || values.role == "C" }
          /> 
        </Col>
        <Col lg={6} className="mt-2">
          <Input
            label="City"
            id="city"
            placeholder={data?.city || "City"}
            disabled={type === 'create' ? false : !editForm}
          />
        </Col>
        <ContactRecord data={data} editContact={editForm} type={type}/>
        {(values?.role === 'F' || data?.role === 'F') &&
          <Col lg={4} className="mt-2 px-3.5">
            <Select
              label="Manager Assigned"
              id="manager"
              options={managerList}
              placeholder={originalManager || "Manager Name"}
              disabled={type === 'create' ? false : !editForm}
            />
          </Col>}
      </FormGroup>
    </>
  );

  return (
    <>
      {primaryContactDetails}
      {editForm ?
        <>
          <Col lg={12} className="mt-2 px-4">
            <Input type="textarea" id="remarks" label="Remarks*" placeholder="Type here..." maxLength={255} />
          </Col>
          <div className="mt-2 mb-2 px-4 d-flex justify-content-between">
            <Button color="cancel" type="button" onClick={() => { type === 'create' ? history.push('/') : toggleEditForm(); }}>
              Cancel
            </Button>
            <Button type="button" color="primary" disabled={Object.keys(errors).length === 0 ? false : true} onClick={() => { if (Object.keys(errors).length === 0) { setShowModal(true) } }}>
              Submit
            </Button>
          </div>
        </> : <div className="mt-2 mb-2 px-4 d-flex justify-content-between">
          <Button color="cancel" type="button" onClick={() => history.push('/')}>
            Cancel
          </Button>
          <Button type="button" color="primary" disabled={isSubmitting} onClick={() => toggleEditForm()}>
            Edit
          </Button>
        </div>
      }
      {confirmationModal}
    </>
  );
};

UserForm.propTypes = {
  data: PropTypes.any,
  type: PropTypes.string,
  getManagerList: PropTypes.func,
  startup: PropTypes.object
}

const mapStateToProps = state => ({
  startup: state.startup,     //whtever is required from reducer
})

export default connect(mapStateToProps, { getManagerList })(withRouter(UserForm));
