/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { CardBody, Card, Container, Form, Row, Col, Toast } from 'reactstrap';
import { Formik } from 'formik';
import { PasswordChange } from '../../components/tvs-components/update-forms';
import { resetPasswordValidate } from '../../helpers/validate';
import { toastr } from 'react-redux-toastr';
import { getUserById } from '../../actions/fetchById';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { resetPassword, clearActionStatus } from '../../actions/crudActions';
import styles from 'styled-components';
import { withRouter } from 'react-router';
import { Breadcrumb } from '../../components/elements';
// export const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

const PasswordChangeForm = ({ match, history, getUserById, resetPassword, clearActionStatus, user, crudState }) => {
  const formikRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({});
  const [data, setData] = useState({});
  const [confirmChanges, setConfirmChanges] = useState(false);
  const toastrOptions = {
    timeOut: 6000,
    closeOnToastrClick: true,
  };

  useEffect(() => {
    getUserById(match?.params?.id);
    setData(user.data);
  }, [match?.params?.id]);

  useEffect(() => {
    setData(user.data)
  }, [user.data])

  useEffect(() => {
    if (crudState?.initiate) {
      const { pending, success, error, message } = crudState;
      setLoading(pending || false);
      if (success) {
        clearActionStatus();
        toastr.success('', message);
        history.push('/getUserList');
      } else if (error) {
        toastr.error('Please check the input values again.', error);
        clearActionStatus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crudState]);

  const submit = async (values) => {
    const { id, email, password, remarks } = values;
    resetPassword({ id, email, password, remarks })
    setConfirmChanges(false);
  };

  return (user.data &&
    <div className="mt-0">
      <Breadcrumb
        data={[
          {
            label: 'User List',
            link: '/',
          },
          {
            label: 'User update',
            link: '/user-detail/' + match.params?.id
          },
          {
            label: 'Reset password'
          }
        ]}
      />
      <Container style={{ paddingBottom: '100px' }}>
        <div className="pt-4 pb-2 ">
          <h1 className="mb-2">Reset password</h1>
          <H5>Reset password of the user</H5>
        </div>
        <div>
          <Row>
            <Col lg={10}>
              <Card className="rounded-0">
                <CardBody className="p-0 rounded-0">
                  <Formik
                    innerRef={(instance) => {
                      formikRef.current = instance;
                    }}
                    initialValues={state}
                    enableReinitialize
                    onSubmit={submit}
                    validate={resetPasswordValidate}
                  >
                    {({ handleSubmit, errors, ...rest }) => (
                      <Form onSubmit={handleSubmit} autoComplete="off">
                        <PasswordChange data={data} />
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

const H5 = styles.h5`
  opacity: 0.8;
`;

PasswordChangeForm.propTypes = {
  getUserById: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
  clearActionStatus: PropTypes.func.isRequired,
  crudState: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  user: state.selectedById,
  crudState: state.crudActions
})

export default connect(mapStateToProps, {
  getUserById, resetPassword,
  clearActionStatus
})(withRouter(PasswordChangeForm));
