import React, { useState, useEffect } from 'react';
import { CardBody, Card, Button, Col } from 'reactstrap';
import styled from 'styled-components';
import { Input, Select } from '../../form';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import ConfirmationModal from './confirmation_modal';
import { connect } from 'react-redux';
import { getCsListForMapping } from '../../../actions/startup';

const CsMapping = ({
  reassignCs,
  setReassignCs,
  setState,
  toogleAddress,
  data,
 startup, getCsListForMapping
}) => {
  const { handleReset, handleSubmit, values, setValues, errors } = useFormikContext();
 
  const [showModal, setShowModal] = useState(false);
  const [confirmChanges, setConfirmChanges] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [csList, setCsList] = useState([])
  const [customerName, setCustomerName] = useState(data?.full_name);
  const [acctNumber, setAcctNumber] = useState(data?.sales?.acct_number);
  const [addCsToggle, setAddCsToggle] = useState(false);
  const confirmationModal = <ConfirmationModal showModal={showModal} handleSubmit={handleSubmit} 
  closeModal={()=>{setShowModal(false); setConfirmChanges(false)}} 
  confirmChanges={()=>{setShowModal(false); setConfirmChanges(true);}}/>

  const toggleCs = () => {
    setAddCsToggle(!addCsToggle);
}
  useEffect(() => {
    getCsListForMapping();
   }, [])
 
   useEffect(() => {
     setCustomerName(data?.full_name);
     setAcctNumber(data?.sales?.acct_number);
   }, [data])
 
   useEffect(() => {
     const data = (startup?.data?.csList || []);
     setFetchedData(startup?.data?.csList || []);
     setCsList(
       data.map((item)=>({
         label: `${item.branch}  ${item.first_name} ${item.last_name}`,
         value: item.first_name+" "+item.last_name
       }))
     )
   }, [startup?.data?.csList])

   useEffect(() => {
    const cs = values?.cs_name;
    const cs_first_name = cs?.split(" ")[0];
    const cs_last_name = cs?.split(" ")[1];
    const cs_data = fetchedData.filter( cs => cs.first_name===cs_first_name && cs.last_name===cs_last_name);
      setValues({
        ...values,
        "cs_phone_number" : cs_data?.[0]?.phone_number,
        "cs_country_code" : cs_data?.[0]?.country_code,
        "client_code" : cs_data?.[0]?.client_code,
        "id" : cs_data?.[0]?.client_code
      })
  }, [values?.cs_name])

  useEffect(()=>{
    setValues({
      ...values,
      "customer_name" : customerName,
      "acct_number" : acctNumber,
      "visit_date" : data?.sales?.r?.visit_date
    })},[customerName,acctNumber]);
  
    useEffect(() => {
    }, [values?.cs_phone_number])

  const cancelEditAddress = () => {
    toogleAddress();
    handleReset();
  };

  const handleSave = () =>{

   if(Object.keys(errors).length===0)
   {setShowModal(true)}
  }
  return data? (
    <Card>
      <CardBody className="p-0">
        <div className="pt-4">
          <div className="px-4 mb-3">
            <div style={{ color: '#2A2A3E' }}>Collection Specialist ID</div>
            <div>{data?.sales?.r?.client_code}</div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: '#2A2A3E' }}>Collection Specialist Name</div>
            <div>{data?.sales?.r?.client_code? data?.sales?.r?.users?.first_name+" " +data?.sales?.r?.users?.last_name : "Unassigned"}</div>
          </div>
          <div className="mb-3">
            {!addCsToggle ? (
              <div className="px-4">
                <Button className="px-4" color="primary" onClick = {()=>{toggleCs()}}>Re-assign</Button>
            
              </div>
            ) : (
              <div className="px-2 py-4" style={{ backgroundColor: 'rgba(42, 42, 62, 0.05)' }}>
                 <Col lg={12} className="mt-2 px-3">
                    <Select
                        label="Collection Specialist"
                        id="cs_name"
                        options={csList}
                        placeholder="Search by name or branch"
                      />
                </Col>
                <div className="px-3">
                  <Input type="textarea" id="remarks" label="Remarks" placeholder="Type here..." maxLength={255}/>
                </div>

                <div className="d-flex justify-content-between mt-5 px-3">
                  <Button
                    color="cancel"
                    onClick={toggleCs}
                    style={{ backgroundColor: 'transparent' }}
                  >
                    Cancel
                  </Button>
                  <Button type="button" color="primary" disabled={Object.keys(errors).length===0?false:true} onClick={()=>handleSave()}>
                    Save
                  </Button>
                </div>
                {confirmationModal}
              </div>
            )}
          </div>
        </div>
        </CardBody>
        </Card>
  ) : (
    <div>Loading ...</div>
  );
};

const Group = styled.div`
  background-color: #fff;
  &.odd {
    background-color: rgba(42, 42, 62, 0.05);
  }
  &.total {
    background-color: #ffcc4d;
  }
`;

const Label = styled.div`
  color: rgba(42, 42, 62, 0.75);
  opacity: 0.7;
`;

const Value = styled.div`
  color: #5a6168;
`;
CsMapping.propTypes = {
  editableAddress: PropTypes.bool,
  setState: PropTypes.func,
  setEditableAddress: PropTypes.func,
  toogleAddress: PropTypes.func,
  data: PropTypes.any,
  setCustLocation: PropTypes.any,
  getCsListForMapping: PropTypes.func,
  startup: PropTypes.object
}

const mapStateToProps = state =>({
  startup: state.startup,     //whtever is required from reducer
})

export default connect(mapStateToProps,{getCsListForMapping})(CsMapping);
