import { Link } from 'react-router-dom';
import React from 'react';
import { sortCaret } from '../../components/elements/Table';

const roleMapping = {
  "C" : "Corporate User",
  "M" : "Manager",
  "A" : "Cashier",
  "F" : "Coll. Specialist"
}

const headerSortingStyle = { backgroundColor: '#c8e6c9' };

export const getUserListingCols = (sort) => [
    {
        dataField: 'id',
        text: 'User Id',
        sort: true,
        order: ['asc','desc'],
        sortCaret: (order, column) => sortCaret(column, sort, order),
        //headerSortingStyle
      },
     
 

  {
    dataField: 'first_name',
    text: 'Name',
    sort: true,
    sortCaret: (order, column) => sortCaret(column, sort),
    formatter: (cell, row) => (
      
      <Link to={row.role === 'A' ? `/cashier-detail/${row.id}` :
                row.role === 'F' ? `/specialist-detail/${row.id}` : 
                `/user-detail/${row.id}`} >
        <ins>
          {cell} {row.last_name}
        </ins>
      </Link>
    ),
  },

  {
    dataField: 'role',
    text: 'Role',
    formatter: (cell, row) => (
      
       
          <span>{roleMapping[`${row.role}`]}</span>
        
      )
  },

  {
    dataField: 'city',
    text: 'City',
  },

  {
    dataField: 'branch',
    text: 'Branch',
  },
  
  {
    dataField: 'email',
    text: 'Email',
  },

  {
    dataField: 'phone_number',
    text: 'Phone Number',

    formatter: (cell, row) => (
        
         
      <span>{row?.country_code} {row?.phone_number}</span>
    
  )
  },
  
];
