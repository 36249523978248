export const PHONE_VALIDATE = [
    {
      value: '+65',
      validate: {
        length: 8,
      },
    },
    {
      value: '+63',
      validate: {
        length: 10,
      },
    },
    {
      value: '+977',
      validate: {
        length: 10,
      },
    },
    {
      value: '+91',
      validate: {
        length: 10,
      },
    },
  ];
  