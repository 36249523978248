/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Select } from '../../form';
import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useFormikContext } from 'formik';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MOBILE_COUNTRY_CODE } from '../../../constant/countrycode';


const GOOGLE_API = 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyC0CkMXNktfIiisGZgMtkGQLPrF7Sg2K4M';
const COUNTRY = 'Philiphines';


const AddressGroupField = ({ col,startup , data}) => {
  const { values, setValues } = useFormikContext();


  const [provinces, setProvinces] = useState({
    currentKey: '',
    current: values.province,
    loading: false,
    data: [],
  });

  const [cities, setCities] = useState({
    loading: false,
    data: [],
  });

  const [zipcodes, setZipcodes] = useState({
    loading: false,
    data: [],
  });

  const [countryCode, setCountryCode] = useState([]);
  const [country, setCountry] = useState({});

  useEffect(() => {
    setCountryCode(
      MOBILE_COUNTRY_CODE.map((item) => ({
        label: `${item.value} ${item.label}`,
        value: item.value,
      })),
    );
  }, []);

  useEffect(() => {
    if(data?.country_code!==undefined){
      setValues({
        ...values,
        country_code : data?.country_code
      })
    }
  }, [data?.country_code])

  useEffect(() => {
    if (startup?.data?.address) {
      const updatedProvinces = { ...provinces };
      const updatedCity = { ...cities };
      const { pending, data, success, error } = startup;
      if (success) {
        const { cities: ct, provinces: pv, zipcodes: zc } = data.address;
        const currentProvince = pv?.find((it) => it.name === values.province);

        // get province list
        Object.assign(updatedProvinces, {
          loading: pending,
          data:
            pv.map((item) => ({
              id: item.name,
              label: item.name,
              value: item.name,
              key: item.key,
            })) || [],
          current: currentProvince,
          currentKey: currentProvince?.key,
        });
        setProvinces(updatedProvinces);

        // get city list
        Object.assign(updatedCity, {
          loading: pending,
          data: ct.map((item) => ({
            id: item.key,
            label: item.key,
            value: item.key,
            province: item.province,
            city: item.city,
          })),
        });
        setCities(updatedCity);

        setZipcodes({
          loading: false,
          data: zc,
        });
      } else {
        toastr.error('Unable get startup data', typeof error === 'string' ? 'error' : '');
      }
    }
  }, [startup?.data?.address]);

  useEffect(() => {
    const findProvince = provinces.data.find((item) => item.label === values.province);

    if (findProvince) {
      setProvinces({
        ...provinces,
        currentKey: findProvince.key,
        current: findProvince,
      });
    }
  }, [values.province]);

  const handleChange = (value => {
    if (value.length === 4) {
      onSearchZipcode(value);
    }
  });

  const onSearchZipcode = async (value) => {
    setCities({
      ...cities,
      loading: true,
    });
    setProvinces({
      ...provinces,
      loading: true,
    });
    let currentProvince = {};
    let data = await getLocationFromGglMap(value, cities, provinces);

    if (!data) {
      data = await getCityFromApi(value, cities.data, provinces.data, zipcodes.data);
    }

    if (data) {
      setValues({
        ...values,
        zipcode: data.zipcode,
        city: data.city,
        province: data.province,
      });
      currentProvince = provinces.data.find((item) => item.label === data?.province);
    } else {
      setValues({
        ...values,
        zipcode: value,
        city: '',
        province: '',
      });
      toastr.error('', 'Unable to retrieve city. Please key in manually or retry again.');
    }

    setCities({
      ...cities,
      loading: false,
    });
    setProvinces({
      ...provinces,
      loading: false,
      current: currentProvince || {},
      currentKey: currentProvince?.key || '',
    });
  };

  const onChangeCity = async (value) => {
    const pv = provinces.data.find((item) => item.key === value.province);
    const updatedValues = { ...values, province: pv.label, city: value.label };
    let data = await getLocationFromGglMap(
      `${values.province}, ${value.label} ${COUNTRY}`,
      cities,
      provinces,
      'postal',
    );

    if (!data) {
      data = getZipcodeFromApi(value.label, zipcodes.data, pv.label);
    }

    if (data) {
      updatedValues.zipcode = data;
    } else {
      updatedValues.zipcode = '';
      toastr.error('', 'Unable to retrieve zipcode. Please key in manually or retry again.');
    }
    setValues(updatedValues);
  };

  const onChangeProvince = (value) => {
    setValues({
      ...values,
      zipcode: '',
      province: value.value,
      city: '',
    });
  };

  return (
    <>
      <Col lg={col || 6}>
        <Input id="zipcode" label="Zipcode/Postcode" handleChange={handleChange} placeholder = {data?.zipcode || ""}/>
      </Col>
      <Col lg={col || 6}>
        <Select
          id="province"
          label="Province"
          options={provinces?.data}
          loading={provinces.loading}
          handleChange={onChangeProvince}
          placeholder = {data?.province || ""}
        />
      </Col>
      {cities.data && (
        <Col lg={col || 6}>
          <Select
            id="city"
            label="City"
            options={cities?.data.filter((it) => it.province === provinces.currentKey)}
            loading={cities.loading}
            handleChange={onChangeCity}
            placeholder = {data?.city || ""}
          />
          {values.city && cities.data.findIndex((it) => it.label === values.city) === -1 && (
            <div className="text-danger text-small" style={{ marginTop: '-10px', marginBottom: '10px' }}>
              Current city is '{values.city}' doesn't map with any options in our database. Please select an option in
              the select box.
            </div>
          )}
        </Col>
      )}
      <Row>
        <Col lg={col || 6}>
          <Label>Phone Number</Label>
          <FormGroup row className="p-0">
            <Col lg={4} className="pr-0">
              <Select
                label=""
                id="country_code"
                options={countryCode}
                // disabled={type==='create'? false : 
                // (data?.country_code!==undefined || data?.country_code!=='' || data.country_code!==null) ? true:!editContact}
                placeholder={data?.country_code || "+00"}
               
              />
            </Col>
            <Col lg={8} className="pl-1">
              <Input id="phone_number" 
              placeholder={data?.new_phone_number? data.new_phone_number: (data?.phone_number || "Type phone number")} 
              //disabled={type!=='create' && !editContact}
              />
            </Col>
          </FormGroup>
        </Col>
        
      </Row>
    </>
  );
};

const getLocationFromGglMap = async (value, cities, provinces, type = 'address') => {
  const request = await fetch(`${GOOGLE_API}&address=${value},${COUNTRY}&components=country:PH`);
  const response = await request.text();
  const { results } = await JSON.parse(response);
  if (results && results[0] && results[0].address_components) {
    const findCity = results[0].address_components.find((item) => findAddressType(type, item));

    if (findCity) {
      const cityDetail = cities.data.find((item) => item.id === findCity.long_name);
      if (cityDetail) {
        const pv = provinces.data.find((item) => item.key === cityDetail.province);

        if (pv) {
          return {
            zipcode: value,
            city: findCity?.long_name ? findCity.long_name.replace(' City', '') : '',
            province: pv.label,
          };
        }
      }
    }
  }
  return null;
};

const findAddressType = (type, item) => {
  switch (type) {
    case 'address':
      return item.types.indexOf('locality') > -1 && item.types.indexOf('political');
    default:
      return item.types.indexOf('postal') > -1;
  }
};

const getCityFromApi = (value, cities, provinces, zipcodes) => {
  const currentZipcode = zipcodes[+value] ? zipcodes[+value] : null;
  let city = '';
  let province = '';
  if (currentZipcode) {
    const mapping = currentZipcode.map((item) => item.replace(' City', ''));
    const cityMapping = cities.find((item) => mapping.indexOf(item.label) > -1);

    const provinceMapping = provinces.find((item) => mapping.indexOf(item.label) > -1);

    if (cityMapping) {
      city = cityMapping.label;
      if (provinceMapping) {
        province = provinceMapping.label;
      } else {
        const pv = provinces.find((item) => item.key === cityMapping.province);
        if (pv) {
          province = pv.label;
        }
      }

      return {
        zipcode: value,
        city,
        province,
      };
    }
  }
  return null;
};

const getZipcodeFromApi = (value, zipcodes, province) => {
  let zc = null;

  const search = [];
  Object.keys(zipcodes).forEach((key) => {
    if (zc) return;
    if (typeof zipcodes[key] === 'string') {
      if (zipcodes[key] === value) zc = key;
    } else {
      const mapping = zipcodes[key].filter((it) => it === value);
      if (mapping.length > 0)
        search.push({
          key,
          data: zipcodes[key],
        });
    }
  });

  const mappingProvince = search.find((item) => item?.data.indexOf(province) > -1);

  if (mappingProvince) {
    zc = mappingProvince.key;
  } else if (search) {
    zc = search[0]?.key?.length === 4 ? search[0]?.key : `0${search[0]?.key}`;
  }
  return zc;
};
AddressGroupField.propTypes = {
  col: PropTypes.any,
  startup: PropTypes.object,
}

const mapStateToProps = state =>({
  startup : state.startup,
})
export default connect(mapStateToProps)(AddressGroupField);
