import { Link } from 'react-router-dom';
import React from 'react';
import { sortCaret } from '../../components/elements/Table';
import moment from 'moment';

export const getCashierPaymentListingCols = (sort) => [
    {
        dataField: 'rec_date',
        text: 'Timestamp',
        order: ['asc', 'desc'],
        sortCaret: (order, column) => sortCaret(column, sort, order),
        formatter: (cell, row) => (
            <Link to={`/collection-detail/${row.id}`}>
                <ins>
                    <div>
                        {moment(row.rec_date).format('DD/MM/YYYY')}
                    </div>
                    <div>
                        {moment(cell).format('hh:mm A')}
                    </div>
                </ins>
            </Link>
        )
    },
    {
        dataField: 'acct_number',
        text: 'Account Number',
    },
    {
        dataField: 'd.month_due_date',
        text: 'Due Date',
        formatter: (cell, row) => (
            <span>{cell !== null || cell !== undefined ? moment(cell).format('DD/MM/YYYY') : '-'}</span>
        )
    },
    {
        dataField: 'd.expected_amt',
        text: 'Total Due *',
        formatter: (cell, row) => (
            <span>{cell !== null || cell !== undefined ? cell : '-'}</span>
        )
    },
    {
        dataField: 'rec_amt',
        text: 'Cash Received *'
    },
    {
        dataField: 'payment_note',
        text: 'Payment Note'
    },
    {
        dataField: 'ptp_date',
        text: 'PTP Date',
        formatter: (cell, row) => (
            <span>{row.ptp_date !== null ? moment(row.ptp_date).format('DD/MM/YYYY') : '-'}</span>
        )
    }

];
