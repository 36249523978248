import React, { useEffect, useRef } from 'react';
import styles from 'styled-components';
import { Formik, Field, Form } from 'formik';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Card, CardBody, Button, FormGroup, Label } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import wheeltek_logo from '../img/Wheeltek Logo.png';
import PropTypes from 'prop-types';
import { login } from '../actions/auth';
import { Redirect } from 'react-router-dom';

export const LoginPage = ({auth,login,history}) => {
  const formikRef = useRef(null);

  useEffect(() => {
    formikRef?.current?.setSubmitting(auth?.pending || false);

    if (auth?.error) {
      const toastrOptions = {
        timeOut: 6000,
        onCloseButtonClick: () => {
          toastr.clean();
        },
        showCloseButton: true,
        closeOnToastrClick: false,
      };

      toastr.error('', 'The username or password is incorrect', toastrOptions);
    }
  }, [auth]);


  if(auth.isAuthenticated){
    return (<Redirect to='/getUserList'/>)
}

  const onSubmit = (values) => {
    login(values.email,values.password,history);
  }

  return (
    <Container className="d-flex justify-content-center align-items-center flex-columns vh-100">
      <CardStyle>
        <CardBody>
          <div className="justify-content-center d-flex mt-3 mb-5">
            <img
              src={wheeltek_logo}
              alt="wheeltek"
              style={{ width: '80%' }}
            />
          </div>
          <Formik
            innerRef={(instance) => {
              formikRef.current = instance;
            }}
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={onSubmit}
            validationSchema={loginSchema}
          >
            {({ touched, errors, isSubmitting } ) => {
    
              return (
                <Form autoComplete="off" className="mx-3">
                  <FormGroupStyle>
                    <LoginLabel for="email">Registered Email Address</LoginLabel>
                    <div>
                      <FieldStyle name="email" type="email" id="email"/>
                      {touched.email && errors.email && <div className="text-danger" id="emailError">{errors.email}</div>}
                    </div>
                  </FormGroupStyle>
                  <FormGroupStyle>
                    <LoginLabel for="password">Password</LoginLabel>
                    <div>
                      <FieldStyle name="password" type="password" id="password"/>
                      {touched.password && errors.password && <div className="text-danger" id="passwordError">{errors.password}</div>}
                    </div>
                  </FormGroupStyle>
                  <FormGroup className="justify-content-start d-flex my-4">
                    <Button color="primary" type="submit" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting' : 'Sign In'}
                    </Button>
                  </FormGroup>
                </Form>
              );
            }}
          </Formik>
        </CardBody>
      </CardStyle>
    </Container>
  );
};

const loginSchema = yup.object({
  email: yup.string().email('Please enter your email address').required('Please enter your email address'),
  password: yup.string().required('Please enter your password '),
});

const LoginLabel = styles(Label)`
  font-size: 19px;
  margin-bottom: 12px;
`;
const FieldStyle = styles(Field)`
  height: 60px;
  padding: 10px;
  width: 100%;
  border: none;
  background-color: #EDF2F7;
  margin-bottom: 5px;
`;
const CardStyle = styles(Card)`
  width: 500px;
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
`;
const FormGroupStyle = styles(FormGroup)`
  text-align: left;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;
const Container = styles.div`
  background-color: #f5f9fb
`;

LoginPage.propTypes = {
    login : PropTypes.func.isRequired,
    auth: PropTypes.object,
}

const mapStateToProps = state =>({
    auth: state.auth   //whtever is required from reducer
})

export default connect(mapStateToProps,{login})(LoginPage);