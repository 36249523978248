/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { CardBody, Card, Container, Form, Row, Col } from 'reactstrap';
import { Formik } from 'formik';
import { UserForm } from '../components/tvs-components/update-forms';
import { createValidate } from '../helpers/validate';
// import { clearActionStatus, createRecord, fetchRecord } from 'redux/crud/action';
// import { State } from 'redux/types';
//import AddressContactRecordPayment from '../../components/tvs-components/update-forms/sidebar';
import { useRouteMatch } from 'react-router';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { registerUser,clearActionStatus } from '../actions/crudActions';
// export const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

const CreateUserForm = ({ clearActionStatus,registerUser, crudState}) => {
  const formikRef = useRef(null);
  const currency = 'PHP';
  const [loading, setLoading] = useState(false)
  const [editableAddress, setEditableAddress] = useState(false);
  const [state, setState] = useState({});
  const [data, setData] = useState({});
  const toogleAddress = () => setEditableAddress(!editableAddress);
  const route = useRouteMatch();
  const toastrOptions = {
    timeOut: 6000,
    closeOnToastrClick: true,
  };
  const [formData, setFromData] = useState({
    first_name: "",
    last_name: "",
    email:"",
    role:"",
    country_code:"",
    phone_number:"",
    city:"",
    branch:"",
    manager_name:"",
    manager_email:""
});


  const [custLocation, setCustLocation] = useState({
    address: '',
    city: '',
    province: '',
    zipcode: '',
  });

  

  // useEffect(() => {
  //   if (!crudState?.startup?.byId?.payload) dispatch(fetchRecord('startup', 'startup'));
  // }, []);

  useEffect(() => {
    if (crudState?.initiate) {
      const { pending, success, error, message } = crudState;
      setLoading(pending || false);
      if (success) {
        setEditableAddress(false);
        clearActionStatus();
        toastr.success('', message);
      } else if (error) {
        toastr.error(message, error);
        clearActionStatus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crudState]);

  

  const submit = async (values) => {

    const {first_name,last_name,email,role,country_code,phone_number,city,branch,
      remarks,manager} = values;

    const manager_email = manager;
    registerUser({first_name,last_name,email,role,country_code,phone_number,
      city,branch,manager_email,remarks})

    // if (values.cust_phone && values.country_code && !showOtp && !values.is_not_provide_phone) {
    //   sendOtp(values.country_code, values.cust_phone);
    // } else {
    //   const cashReceived =
    //     values.cash_received || values.cash_received === 0 ? +values.cash_received.split(',').join('') : 0;
    //   const postData = makeupPostData({
    //     ...values,
    //     due_id: data?.due?.id,
    //     rid: data?.id,
    //     cash_received: cashReceived,
    //     ptp_amount: values.ptp_amount || values.ptp_amount === 0 ? +values.ptp_amount.split(',').join('') : null,
    //     acct_number: data?.acct_number,
    //     payment_otp: values.payment_otp,
    //     customer_id: data?.customer?.id,
    //     total_expected_amt: values.total_expected,
    //   });

    //   if (values.is_not_provide_phone) {
    //     delete postData.country_code;
    //     delete postData.cust_phone;
    //     delete postData.phone_number_relationship;
    //   }

    //   dispatch(createRecord('payment', 'cms/payment', postData));
    // }
  };

  function capitalizeTheFirstLetterOfEachWord(words) {
    const separateWord = words.toLowerCase().split(' ');
    for (let i = 0; i < separateWord.length; i++) {
      separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(' ');
  }



  const handleSaveAddress = (values) => {
    setData({
      ...data,
      user: {
        ...data.user,
        address: values.address,
        city: values.city,
        province: values.province,
        zipcode: values.zipcode,
      },
    });

    setCustLocation({
      address: values.address,
      city: values.city,
      province: values.province,
      zipcode: values.zipcode,
    });

  
  };

  // const customerValidate = (values) => {
  //   const errors = custValidate(values, crudState?.startup?.byId?.payload?.cities);
  //   return errors;
  // };

  return (
    
      <Container style={{ paddingBottom: '100px' }}>
        <div className="pt-4 pb-2 ">
          <h1 className="mb-4">Create User</h1>
        </div>
        <>
          <Row>
            <Col lg={8}>
              <Card className="rounded-0">
                <CardBody className="p-0 rounded-0">
                  <Formik
                    innerRef={(instance) => {
                      formikRef.current = instance;
                    }}
                    initialValues={state}
                    enableReinitialize
                    onSubmit={submit}
                    validate={createValidate}
                  >
                    {({handleSubmit, errors, ...rest }) => (
                      <Form onSubmit={handleSubmit} autoComplete="off">
                        <UserForm type={"create"}/>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>      
          </Row>
        </>
      </Container>
    
  );
};

CreateUserForm.propTypes = {
  
  registerUser : PropTypes.func.isRequired,
  clearActionStatus : PropTypes.func.isRequired,
  crudState : PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  
  crudState : state.crudActions
})

export default connect(mapStateToProps,{ registerUser, clearActionStatus})(CreateUserForm);
