/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { BranchDropdown, ActivityDropdown } from '../../components/elements';
import { Container, Label } from 'reactstrap';
import Boundary from '../../components/layouts/Boundary';
import { Table } from '../../components/elements'
import styles from 'styled-components';
import { getUserList } from '../../actions/fetchCollection';
import { getUserListingCols } from './columns';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getBranchList } from '../../actions/startup';
import { useLocation } from 'react-router';

const UserListingContainer = ({ collections, getUserList, getBranchList, startup }) => {
  const [state, setState] = useState([]);
  const [total, setTotal] = useState(0);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableState, setTableState] = useState({
    filter: {
      branch: 'All',
      role: '',
      search: '',
      active: ''
    },
    page: 1,
    sizePerPage: 20,
  });
  const [header, setHeader] = useState("User");

  const [sort, setSort] = useState({
    s: '',
    o: '',
  });

  const [sortState, setSortState] = useState({
    id: '',
    first_name: '',
  })

  const location = useLocation();

  useEffect(() => {
    if (!startup?.data?.branch) {
      getBranchList();
    }
  }, []);

  useEffect(() => {
    setBranches(startup?.data?.branch || []);
  }, [startup?.data?.branch]);

  useEffect(() => {
    getUserList(tableState);
  }, [tableState]);

  useEffect(() => {
    if (location.pathname === '/getUserList') {
      var role = ''; var headerTitle = "Users";
    } else if (location?.pathname === '/corporateuser') {
      role = 'C'; headerTitle = "Corporate Users";
    } else if (location?.pathname === '/manager') {
      role = 'M'; headerTitle = "Managers";
    } else if (location?.pathname === '/cashier') {
      role = 'A'; headerTitle = "Cashiers";
    } else if (location?.pathname === '/specialist') {
      role = 'F'; headerTitle = "Collection Specialists";
    }
    setTableState({
      ...tableState,
      filter: {
        ...tableState.filter,
        role: role,
      }
    });
    setHeader(headerTitle);
  }, [location])

  const onTableChange = (type, value) => {
    if (type === 'pagination') {
      setTableState({
        ...tableState,
        page: value.page,
      });
    } else if (type === 'search') {
      setTableState({
        ...tableState,
        page: 1,
        filter: {
          ...tableState.filter,
          search: value
        }
      })
    }
    if (type === 'sort') {
      const sortField = value.sortField;
      var sortOrder = 'desc';
      if (sortState[sortField] !== '') {
        if (sortState[sortField] === 'desc') {
          sortOrder = 'asc'
        }
      }
      sortState[sortField] = sortOrder
      setSortState({
        ...sortState
      })
      setSort({
        s: value.sortField,
        o: sortOrder,
      });
      setTableState({
        ...tableState,
        page: 1,
        filter: {
          ...tableState.filter,
          sort: {
            s: value.sortField,
            o: sortOrder
          }
        }
      })
    }
  };

  useEffect(() => {
    setLoading(collections?.pending || false);
    setState(collections?.data || []);
    setTotal(collections?.otherInfo?.count || 0);
  }, [collections]);



  const handleChangeBranch = (item) => {
    setTableState({
      ...tableState,
      filter: {
        ...tableState.filter,
        branch: item,
      },
    });
  };

  const handleChangeActivity = (item) => {
    var activity = item === 'Active' ? 'Y' : 'N';
    if (item === 'All') {
      activity = ''
    }
    setTableState({
      ...tableState,
      filter: {
        ...tableState.filter,
        active: activity,
      },
    });
  }

  return (
    <>
      <Container>
        <div className="pt-4 pb-2">
          <h1>List of {header}</h1>
          <H3>As of today, {`${moment().format('DD MMM y')} at ${moment().format('h:mma')}`}</H3>
          <div className="d-flex justify-content-between my-3">
            <BranchDropdown data={branches} handleChange={handleChangeBranch} header={"Branch"} />
            <ActivityDropdown data={['Active', 'Inactive']} handleChange={handleChangeActivity} header={'Activity'} />
            <Link to='/createUser'>
              <Button className="btn btn-primary" color="primary">Create new user</Button>
            </Link>
          </div>
        </div>

        <Card className="my-4">
          <CardBody>
            <Label
              style={{
                color: '#2A2A3E',
              }}
              for="searchInput"
            >
              Search by Name, Email id or phone number
              <span style={{ color: '#2A2A3E', opacity: 0.5, marginLeft: '10px' }}>(Minimum 3 characters)</span>
            </Label>
            <div className="position-relative">
              <DebounceInput
                id="searchInput"
                minLength={3}
                debounceTimeout={500}
                onChange={(event) => onTableChange('search', event.target.value)}
                placeholder="Type here"
                className="form-control"
                value={state?.filter?.search || ''}
              />
              {state?.filter?.search?.length >= 3 && (
                <Button
                  color="primary"
                  onClick={() => onTableChange('search', '')}
                  className="position-absolute circle-close"
                  style={{ width: '20px', height: '20px', top: '15px', right: '15px' }}
                />
              )}
            </div>
          </CardBody>
        </Card>
        <Boundary loading={loading}>
          <>
            <div className="table-note">
              <Label className="label-color">
                {total > tableState.sizePerPage * tableState.page ? tableState.sizePerPage * tableState.page : total} of {total} entries
              </Label>
            </div>
            <div id="userTable">
              <Table
                data={state}
                page={tableState.page}
                columns={getUserListingCols(sort)}
                sizePerPage={tableState.sizePerPage}
                totalSize={total}
                onTableChange={onTableChange}
                keyField="id"
              />
            </div>
          </>
        </Boundary>
      </Container>
    </>
  );
};

const H3 = styles.h3`
  opacity: 0.8;
`;

UserListingContainer.propTypes = {
  collections: PropTypes.object.isRequired,
  getUserList: PropTypes.func.isRequired,
  getBranchList: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  collections: state.collections,     //whtever is required from reducer
  startup: state.startup
})
export default connect(mapStateToProps, { getUserList, getBranchList })(UserListingContainer);

