import React, { useState } from 'react';
import { CardBody, Card, Button } from 'reactstrap';
import styled from 'styled-components';
import { Input } from '../../form';
import { useFormikContext } from 'formik';
import AddressGroupField from './pmt-address';
import PropTypes from 'prop-types';
import ConfirmationModal from './confirmation_modal';


const AddressContactRecordPayment = ({
  editableAddress,
  setEditableAddress,
  setState,
  toogleAddress,
  data,
  setCustLocation,
}) => {
  const { handleReset, handleSubmit, values, setValues, errors} = useFormikContext();
  
  const [showModal, setShowModal] = useState(false);
  const [confirmChanges, setConfirmChanges] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const toggleContact=()=>{
    setEditContact(!editContact);
  }

  const confirmationModal = <ConfirmationModal showModal={showModal} handleSubmit={handleSubmit} 
  closeModal={()=>{setShowModal(false); setConfirmChanges(false)}} 
  confirmChanges={()=>{setShowModal(false); setConfirmChanges(true);}}/>

  const cancelEditAddress = () => {
    toogleAddress();
    handleReset();
  };

  const handleSubmitTemp = (e) => {
    if(Object.keys(errors).length===0){
    setShowModal(true);
      const submitType = e.target.id;
      setValues({
        ...values,
        "submitType" : submitType,
      })
    }
  }

  return data? (
    <Card>
      <CardBody className="p-0">
        <div className="pt-4">
        <div className="px-4 mb-3">
            <div style={{ color: '#2A2A3E' }}>Customer Info</div>
            <div>{`${data?.sales?.acct_number}  |   ${data?.full_name}`}</div>
          </div>
          {/* <div className="px-4 mb-3">
            <div style={{ color: '#2A2A3E' }}>Customer Name</div>
            <div>{data?.full_name}</div>
          </div> */}
          <div className="mb-3 mt-2">
            {!editableAddress ? (
              <div className="px-4">
                <div style={{ color: '#2A2A3E' }}>Customer Address</div>
                {data && (
                  <div>{`${data?.zipcode}, ${data?.province}, ${data?.city}, ${data?.address}`}</div>
                )}
              <div className="mt-3"></div>
              <div style={{ color: '#2A2A3E' }}>Phone Number</div>
                {data && (
                  <div>{`${data?.country_code || "+00"} ${data?.phone_number || ""}`}</div>
                )}
                <div className="mt-3"></div>
                <Button className="pt-4" color="primary" onClick={() => setEditableAddress(true)}>
                  Edit
                </Button>
              </div>
            ) : (
              <div className="px-4 py-4" style={{ backgroundColor: 'rgba(42, 42, 62, 0.05)' }}>
                <AddressGroupField col={6} data={data}/>
                
                <div className="px-1 col-sm-6">
                  <Input type="textarea" id="address" label="Address" placeholder={data?.address || ""} />
                </div>
                

                <div className="d-flex justify-content-between mt-5 px-3">
                  <Button
                    color="cancel"
                    className="text-danger "
                    onClick={cancelEditAddress}
                    style={{ backgroundColor: 'transparent' }}
                  >
                    Cancel
                  </Button>
                  <Button type="button" color="primary" onClick={()=>{if(Object.keys(errors).length===0){setShowModal(true)}}}
                  disabled={Object.keys(errors).length===0?false:true}>
                    Save
                  </Button>
                  {confirmationModal}
                </div>
              </div>
            )}
          </div>
        </div>
        
        <hr />
      </CardBody>
    </Card>
  ) : (
    <div>Loading ...</div>
  );
};

const Group = styled.div`
  background-color: #fff;
  &.odd {
    background-color: rgba(42, 42, 62, 0.05);
  }
  &.total {
    background-color: #ffcc4d;
  }
`;

const Label = styled.div`
  color: rgba(42, 42, 62, 0.75);
  opacity: 0.7;
`;

const Value = styled.div`
  color: #5a6168;
`;
AddressContactRecordPayment.propTypes = {
  editableAddress: PropTypes.bool,
  setState: PropTypes.func,
  setEditableAddress: PropTypes.func,
  toogleAddress: PropTypes.func,
  data: PropTypes.any,
  setCustLocation: PropTypes.any,
}
export default AddressContactRecordPayment;
