import axios from "axios";
import {
    UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILED,
    UPDATE_CUSTOMER, UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_FAILED,
    CREATE_USER, CREATE_USER_SUCCESS, ASSIGN_CS,
    ASSIGN_CS_SUCCESS, ASSIGN_CS_FAILED, CREATE_USER_FAILED,
    DEACTIVATE_USER, DEACTIVATE_USER_SUCCESS, DEACTIVATE_USER_FAILED,
    REACTIVATE_USER, REACTIVATE_USER_SUCCESS, REACTIVATE_USER_FAILED,
    RESET_PHONE_NUMBER_USER, RESET_PHONE_NUMBER_USER_SUCCESS, RESET_PHONE_NUMBER_USER_FAILED,
    SEND_USER_CREDENTIAL, SEND_USER_CREDENTIAL_SUCCESS, SEND_USER_CREDENTIAL_FAILED,
    CLEAR_ACTION_STATUS, SEND_NEWUSER_EMAIL, SEND_NEWUSER_EMAIL_SUCCESS, SEND_NEWUSER_EMAIL_FAILED
} from "../actions/types";
import { BaseURL } from "../constant";
import { setAlert } from "./alert";


export const updateUser = ({ id, name, email, existing_role, new_email, new_country_code, new_phone_number
    , role, country_code, phone_number, city, branch, manager_email, remarks }) =>
    async dispatch => {
        dispatch({ type: UPDATE_USER });
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const body = JSON.stringify({
            id, name, email, existing_role, new_email, new_country_code, new_phone_number,
            role, country_code, phone_number, city, branch, manager_email, remarks
        });
        var res = {}
        try {
            if (existing_role === "M" || existing_role === "C" || existing_role === "A") {

                res = await axios.patch(BaseURL + '/admin/updateCMSuser', body, config);

            } else if (existing_role === "F") {

                res = await axios.patch(BaseURL + '/admin/updateCS', body, config);

            } else {
                role = existing_role;
            }
            dispatch({
                type: UPDATE_USER_SUCCESS,
                payload: "User Updated Successfully!"
            });
        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: UPDATE_USER });
                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }

                    const body = JSON.stringify({
                        id, name, email, existing_role, new_email, new_country_code, new_phone_number,
                        role, country_code, phone_number, city, branch, manager_email, remarks
                    });
                    var res = {}
                    try {
                        if (existing_role === "M" || existing_role === "C" || existing_role === "A") {

                            res = await axios.patch(BaseURL + '/admin/updateCMSuser', body, config);

                        } else if (existing_role === "F") {

                            res = await axios.patch(BaseURL + '/admin/updateCS', body, config);

                        } else {
                            role = existing_role;
                        }
                        dispatch({
                            type: UPDATE_USER_SUCCESS,
                            payload: "User Updated Successfully!"
                        });
                    } catch (err) {
                        dispatch({
                            type: UPDATE_USER_FAILED,
                            payload: "Failed to update the user : " + err.response.data.message
                        })
                    }
                } else {
                    dispatch({
                        type: UPDATE_USER_FAILED,
                        payload: "Failed to update the user : " + err.response.data.message
                    })
                }
            } else {
                dispatch({
                    type: UPDATE_USER_FAILED,
                    payload: "Failed to update the user : " + err.response.data.message
                })
            }
        }
    }

export const updateCustomer = ({ id, acct_number, full_name, address, address2, province, zipcode, city, country, country_code, phone_number,
    new_phone_number, remarks }) =>
    async dispatch => {

        dispatch({ type: UPDATE_CUSTOMER })
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const body = JSON.stringify({
            id, acct_number, full_name, address, address2, province, zipcode, city, country, country_code, phone_number,
            new_phone_number, remarks
        });
        try {

            const res = await axios.patch(BaseURL + '/admin/updateCustomer', body, config);

            dispatch({
                type: UPDATE_CUSTOMER_SUCCESS,
                payload: "Customer updated!"
            });
            dispatch(setAlert("Customer updated", "primary"))
        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: UPDATE_CUSTOMER })
                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }

                    const body = JSON.stringify({
                        id, acct_number, full_name, address, address2, province, zipcode, city, country, country_code, phone_number,
                        new_phone_number, remarks
                    });
                    try {

                        const res = await axios.patch(BaseURL + '/admin/updateCustomer', body, config);

                        dispatch({
                            type: UPDATE_CUSTOMER_SUCCESS,
                            payload: "Customer updated!"
                        });
                        dispatch(setAlert("Customer updated", "primary"))
                    } catch (err) {
                        dispatch(setAlert(err.response.data.message, 'danger'));
                        dispatch({
                            type: UPDATE_CUSTOMER_FAILED,
                            payload: "Unable to update customer " + err.response.data.message
                        })
                    }
                } else {
                    dispatch(setAlert(err.response.data.message, 'danger'));
                    dispatch({
                        type: UPDATE_CUSTOMER_FAILED,
                        payload: "Unable to update customer " + err.response.data.message
                    })
                }
            } else {
                dispatch(setAlert(err.response.data.message, 'danger'));
                dispatch({
                    type: UPDATE_CUSTOMER_FAILED,
                    payload: "Unable to update customer " + err.response.data.message
                })
            }
        }
    }

export const updateDues = ({ id, sales_id, install_num, month_due_date, expected_amt, penalty_due, rebate, remarks }) =>
    async dispatch => {
        dispatch({ type: UPDATE_CUSTOMER });

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const body = JSON.stringify({ id, sales_id, install_num, month_due_date, expected_amt, penalty_due, rebate, remarks });

        try {

            const res = await axios.patch(BaseURL + '/admin/updateDues', body, config);

            dispatch({
                type: UPDATE_CUSTOMER_SUCCESS,
                payload: "Customer dues data updated!"
            });

        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: UPDATE_CUSTOMER });

                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }

                    const body = JSON.stringify({ id, sales_id, install_num, month_due_date, expected_amt, penalty_due, rebate, remarks });

                    try {

                        const res = await axios.patch(BaseURL + '/admin/updateDues', body, config);

                        dispatch({
                            type: UPDATE_CUSTOMER_SUCCESS,
                            payload: "Customer dues data updated!"
                        });

                    } catch (err) {
                        dispatch(setAlert(err.response.data.message, 'danger'));
                        dispatch({
                            type: UPDATE_CUSTOMER_FAILED,
                            payload: "Unable to update customer dues" + err.response.data.message
                        })
                    }
                } else {
                    dispatch(setAlert(err.response.data.message, 'danger'));
                    dispatch({
                        type: UPDATE_CUSTOMER_FAILED,
                        payload: "Unable to update customer dues" + err.response.data.message
                    })
                }
            } else {
                dispatch(setAlert(err.response.data.message, 'danger'));
                dispatch({
                    type: UPDATE_CUSTOMER_FAILED,
                    payload: "Unable to update customer dues" + err.response.data.message
                })
            }
        }
    }

export const registerUser = ({ first_name, last_name, email, role, country_code, phone_number, city,
    branch, manager_email, remarks }) =>
    async dispatch => {
        dispatch({ type: CREATE_USER })
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const body = JSON.stringify({
            first_name, last_name, email, role, country_code, phone_number, city,
            branch, manager_email, remarks
        });
        let sucessfulTry = true
        var res = {}
        try {
            if (role === "M" || role === "C") {

                res = await axios.post(BaseURL + '/admin/createManager', body, config);

            } else if (role === "A") {

                res = await axios.post(BaseURL + '/admin/createCashier', body, config);

            } else if (role === "F") {
                res = await axios.post(BaseURL + '/admin/createCS', body, config);

            } else {
                sucessfulTry = false
                dispatch(setAlert("Roles : { C: Corporate, M: Manager, A: Cashier, F: Collection Specialist }", 'danger', 8000))
            }
            if (sucessfulTry) {
                dispatch({type: SEND_NEWUSER_EMAIL})
                console.log('in success')
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                console.log('in line 326')
                const body = JSON.stringify({email, role}) 
                let sucessfulTry = true
                var res = {}
                console.log('sending request to abckend to send email')
                res = await axios.post(BaseURL + '/admin/registerEmail', body, config);
                console.log('waiting for response from backend')
                if (sucessfulTry) {
                    console.log('Registration Email has been sent')
                } else {
                    console.log('Registration Email failed')
                }
                dispatch({
                    type: CREATE_USER_SUCCESS,
                    payload: "User registered!"
                });
                dispatch(setAlert("User registered and email has been sent", "primary"))
            }
        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: CREATE_USER })
                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }

                    const body = JSON.stringify({
                        first_name, last_name, email, role, country_code, phone_number, city,
                        branch, manager_email, remarks
                    });
                    let sucessfulTry = true
                    var res = {}
                    try {
                        if (role === "M" || role === "C") {

                            res = await axios.post(BaseURL + '/admin/createManager', body, config);

                        } else if (role === "A") {

                            res = await axios.post(BaseURL + '/admin/createCashier', body, config);

                        } else if (role === "F") {
                            res = await axios.post(BaseURL + '/admin/createCS', body, config);

                        } else {
                            sucessfulTry = false
                            dispatch(setAlert("Roles : { C: Corporate, M: Manager, A: Cashier, F: Collection Specialist }", 'danger', 8000))
                        }
                        if (sucessfulTry) {
                            dispatch({
                                type: CREATE_USER_SUCCESS,
                                payload: "User registered!"
                            });
                            dispatch(setAlert("User registered", "primary"))
                        }
                    } catch (err) {
                        dispatch(setAlert(err.response.data.message, 'danger'));
                        dispatch({
                            type: CREATE_USER_FAILED,
                            payload: "User registration failed : " + err.response.data.message
                        })
                    }
                } else {
                    dispatch(setAlert(err.response.data.message, 'danger'));
                    dispatch({
                        type: CREATE_USER_FAILED,
                        payload: "User registration failed : " + err.response.data.message
                    })
                }
            } else {
                dispatch(setAlert(err.response.data.message, 'danger'));
                dispatch({
                    type: CREATE_USER_FAILED,
                    payload: "User registration failed : " + err.response.data.message
                })
            }
        }
    }
export const csMapping = ({ customer_name, acct_number, cs_name, cs_country_code, cs_phone_number, visit_date, client_code, id, remarks }) =>
    async dispatch => {
        dispatch({ type: ASSIGN_CS })
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const body = JSON.stringify({ customer_name, acct_number, cs_name, cs_country_code, cs_phone_number, visit_date, client_code, id, remarks });
        var res = {}
        try {

            res = await axios.patch(BaseURL + '/admin/customerToCsMapping', body, config);

            dispatch({
                type: ASSIGN_CS_SUCCESS,
                payload: "Colection specialist assigned successfully!"
            });

        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: ASSIGN_CS })
                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }

                    const body = JSON.stringify({ customer_name, acct_number, cs_name, cs_country_code, cs_phone_number, visit_date, client_code, id, remarks });
                    var res = {}
                    try {

                        res = await axios.patch(BaseURL + '/admin/customerToCsMapping', body, config);

                        dispatch({
                            type: ASSIGN_CS_SUCCESS,
                            payload: "Colection specialist assigned successfully!"
                        });

                    } catch (err) {
                        dispatch({
                            type: ASSIGN_CS_FAILED,
                            payload: "Collection Specialist assigning failed! : " + err.response.data.message
                        })
                    }
                } else {
                    dispatch({
                        type: ASSIGN_CS_FAILED,
                        payload: "Collection Specialist assigning failed! : " + err.response.data.message
                    })
                }
            } else {
                dispatch({
                    type: ASSIGN_CS_FAILED,
                    payload: "Collection Specialist assigning failed! : " + err.response.data.message
                })
            }
        }
    }

export const deactivateUser = ({ id, name, role }) =>
    async dispatch => {
        dispatch({ type: DEACTIVATE_USER })
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        const body = JSON.stringify({ id, name, role });

        try {

            const res = await axios.patch(BaseURL + '/admin/deactivateUser', body, config);

            dispatch({
                type: DEACTIVATE_USER_SUCCESS,
                payload: "User Deactivated"
            });

        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: DEACTIVATE_USER })
                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                    const body = JSON.stringify({ id, name, role });

                    try {

                        const res = await axios.patch(BaseURL + '/admin/deactivateUser', body, config);

                        dispatch({
                            type: DEACTIVATE_USER_SUCCESS,
                            payload: "User Deactivated"
                        });

                    } catch (err) {
                        dispatch({
                            type: DEACTIVATE_USER_FAILED,
                            payload: "De-activation failed : " + err.response.data.message
                        })
                    }
                } else {
                    dispatch({
                        type: DEACTIVATE_USER_FAILED,
                        payload: "De-activation failed : " + err.response.data.message
                    })
                }
            } else {
                dispatch({
                    type: DEACTIVATE_USER_FAILED,
                    payload: "De-activation failed : " + err.response.data.message
                })
            }
        }
    };

export const reactivateUser = ({ id, name, role }) =>
    async dispatch => {
        dispatch({ type: REACTIVATE_USER })
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        const body = JSON.stringify({ id, name, role });

        try {

            const res = await axios.patch(BaseURL + '/admin/reactivateUser', body, config);

            dispatch({
                type: REACTIVATE_USER_SUCCESS,
                payload: "User Reactivated"
            });

        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: REACTIVATE_USER })
                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                    const body = JSON.stringify({ id, name, role });

                    try {

                        const res = await axios.patch(BaseURL + '/admin/reactivateUser', body, config);

                        dispatch({
                            type: REACTIVATE_USER_SUCCESS,
                            payload: "User Reactivated"
                        });

                    } catch (err) {
                        dispatch({
                            type: REACTIVATE_USER_FAILED,
                            payload: "Re-activation failed : " + err.response.data.message
                        })
                    }
                } else {
                    dispatch({
                        type: REACTIVATE_USER_FAILED,
                        payload: "Re-activation failed : " + err.response.data.message
                    })
                }
            } else {
                dispatch({
                    type: REACTIVATE_USER_FAILED,
                    payload: "Re-activation failed : " + err.response.data.message
                })
            }
        }
    }

export const resetUserPhoneNumber = ({ id }) =>
    async dispatch => {
        dispatch({ type: RESET_PHONE_NUMBER_USER })
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        const body = JSON.stringify({ id })

        try {
            const res = await axios.patch(BaseURL + "/admin/resetUserPhoneNumber", body, config)
            dispatch({
                type: RESET_PHONE_NUMBER_USER_SUCCESS,
                payload: "Reset phone number"
            });
        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: RESET_PHONE_NUMBER_USER })
                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                    const body = JSON.stringify({ id })

                    try {
                        const res = await axios.patch(BaseURL + "/admin/resetUserPhoneNumber", body, config)
                        dispatch({
                            type: RESET_PHONE_NUMBER_USER_SUCCESS,
                            payload: "Reset phone number"
                        });
                    } catch (err) {
                        dispatch({
                            type: RESET_PHONE_NUMBER_USER_FAILED,
                            payload: "Reset phone number failed: " + err.response.data.message
                        })
                    }
                } else {
                    dispatch({
                        type: RESET_PHONE_NUMBER_USER_FAILED,
                        payload: "Reset phone number failed: " + err.response.data.message
                    })
                }
            } else {
                dispatch({
                    type: RESET_PHONE_NUMBER_USER_FAILED,
                    payload: "Reset phone number failed: " + err.response.data.message
                })
            }
        }
    }

export const resetPassword = ({ id, email, password, remarks }) =>
    async dispatch => {
        dispatch({ type: UPDATE_USER })
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        const body = JSON.stringify({ id, email, password, remarks });

        try {

            const res = await axios.patch(BaseURL + '/admin/resetPassword', body, config);

            dispatch({
                type: UPDATE_USER_SUCCESS,
                payload: "Password changed"
            });

        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: UPDATE_USER })
                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                    const body = JSON.stringify({ id, email, password, remarks });

                    try {

                        const res = await axios.patch(BaseURL + '/admin/resetPassword', body, config);

                        dispatch({
                            type: UPDATE_USER_SUCCESS,
                            payload: "Password changed"
                        });

                    } catch (err) {
                        dispatch({
                            type: UPDATE_USER_FAILED,
                            payload: "Password reset failed : " + err.response.data.message
                        })
                    }
                } else {
                    dispatch({
                        type: UPDATE_USER_FAILED,
                        payload: "Password reset failed : " + err.response.data.message
                    })
                }
            } else {
                dispatch({
                    type: UPDATE_USER_FAILED,
                    payload: "Password reset failed : " + err.response.data.message
                })
            }
        }
    }

export const sendUserCredential = ({ email }) =>
    async dispatch => {
        dispatch({ type: SEND_USER_CREDENTIAL })
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        const body = JSON.stringify({ email })

        try {
            const res = await axios.post(BaseURL + '/admin/sendEmail', body, config)
            dispatch({
                type: SEND_USER_CREDENTIAL_SUCCESS,
                payload: "Sent user credential"
            });

        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: SEND_USER_CREDENTIAL })
                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                    const body = JSON.stringify({ email })

                    try {
                        const res = await axios.post(BaseURL + '/admin/sendEmail', body, config)
                        dispatch({
                            type: SEND_USER_CREDENTIAL_SUCCESS,
                            payload: "Sent user credential"
                        });

                    } catch (err) {
                        dispatch({
                            type: SEND_USER_CREDENTIAL_FAILED,
                            payload: "Sending user credential failed : " + err.response.data.message
                        })
                    }
                } else {
                    dispatch({
                        type: SEND_USER_CREDENTIAL_FAILED,
                        payload: "Sending user credential failed : " + err.response.data.message
                    })
                }
            } else {
                dispatch({
                    type: SEND_USER_CREDENTIAL_FAILED,
                    payload: "Sending user credential failed : " + err.response.data.message
                })
            }
        }
    }

export const clearActionStatus = () =>
    async dispatch => {
        dispatch({ type: CLEAR_ACTION_STATUS });
    }
