import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types'

const ConfirmationModal = ({showModal,closeModal,handleSubmit,message,header}) => {
  // const { values, isSubmitting, setValues } = useFormikContext();

  const handleSubmitTemp = () =>{
      closeModal();      
      handleSubmit();
  }
  return (
    <Modal isOpen={showModal} centered size="md" backdrop="static">
      <ModalBody className="p-4">
        <h1 style={{ fontWeight: 300 }} className="d-flex justify-content-between">
         {header || "Update Confirmation"}
          <Button color="x" onClick={closeModal}>
            <img src={`${process.env.PUBLIC_URL}/images/close.png`} alt="close" />
          </Button>
        </h1>
        <div className="mb-3">
        {message || "Are you sure you want to submit the changes?"}
        </div>
        <div className="text-center">
          <div className="mt-2 d-flex justify-content-between">
            <Button
              color="danger"
              className="py-2 px-5"
              type="submit"
              onClick={()=>handleSubmitTemp()}
            >
              Yes
            </Button>
            <Button color="primary" 
            // disabled={isSubmitting} 
            type="button"
            onClick={closeModal}>
              No
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
    showModal : PropTypes.bool,
    closeModal : PropTypes.func,
    handleSubmit : PropTypes.func,
    message : PropTypes.string,
    header : PropTypes.string,
}
export default ConfirmationModal;