import React, { useState, useEffect } from 'react';
import { Col, FormGroup, Label,Row } from 'reactstrap';
import { Input, Select } from '../../form';
import { MOBILE_COUNTRY_CODE } from '../../../constant/countrycode';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types'


const ContactRecord = ({data,type, editContact=true}) => {
  const [countryCode, setCountryCode] = useState([]);
  const [country, setCountry] = useState({});
  const { values, setValues } = useFormikContext();
  useEffect(() => {
    setCountryCode(
      MOBILE_COUNTRY_CODE.map((item) => ({
        label: `${item.value} ${item.label}`,
        value: item.value,
      })),
    );
  }, []);

  useEffect(() => {
    if(data?.country_code!==undefined){
      setValues({
        ...values,
        country_code : data?.country_code
      })
    }
  }, [data?.country_code])

  return (
    <div className="pt-2">
      <Row>
        <Col lg={12}>
          <FormGroup row className="p-0">
            <Col lg={4} className="pr-0">
              <Select
                label="Country Code"
                id="country_code"
                options={countryCode}
                disabled={type==='create'? false : (data?.country_code? true : !editContact)}
                placeholder = {data?.country_code || "+00"}
              />
            </Col>
            <Col lg={8} className="pl-1">
              <Input id="phone_number" label="Phone Number"
              placeholder={data?.phone_number || "Type phone number"} 
              disabled={type!=='create' && !editContact}/>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
ContactRecord.propTypes = {
  data : PropTypes.any,
  type : PropTypes.string,
  editContact : PropTypes.bool,
}
export default ContactRecord;
