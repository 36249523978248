import React, { useState } from 'react';
import { Col, FormGroup, Button } from 'reactstrap';
import { useFormikContext } from 'formik';
import { Datepicker, Input } from '../../form';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import ConfirmationModal from './confirmation_modal';


const CustomerForm = ({history,data}) => {
  const [showModal, setShowModal] = useState(false);
  const [confirmChanges, setConfirmChanges] = useState(false);
  const [editDues, setEditDues] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const { values, setValues, isSubmitting, handleSubmit} = useFormikContext();
  
  const toggleDues=()=>{
    setEditDues(!editDues);
  }

  const toggleContact=()=>{
    setEditContact(!editContact);
  }

  const currency = 'PHP';

  const confirmationModal = <ConfirmationModal showModal={showModal} handleSubmit={handleSubmit} 
  closeModal={()=>{setShowModal(false); setConfirmChanges(false)}} 
  confirmChanges={()=>{setShowModal(false); setConfirmChanges(true);}}/>

  const handleSubmitTemp = (e) => {
    setShowModal(true);
      const submitType = e.target.id;
      setValues({
        ...values,
        "submitType" : submitType,
      })
    
  }

  const date = (moment(data?.sales?.dues?.[0]?.month_due_date)).format("DD/MM/YYYY")
  const duesDetail = (
    <FormGroup row className="px-4 pt-4 pb-1">
        <Col lg={6}>
          <Input label="Installment Number" type="text"  id="install_num" 
          placeholder={data?.sales?.dues?.[0]?.install_num || "Installment Number"}
          disabled={!editDues} />
        </Col>
      
      
        <Col lg={6}>
          <Input
            label="Dues"
            id="expected_amt"
            inputIcon={<span>{currency}</span>}
            placeholder={data?.sales?.dues?.[0]?.expected_amt || "Expected Amount"}
            formatType="currency"
            disabled={!editDues}
          />
        </Col>

        <Col lg={6} className = "mt-2">
          <Datepicker id="month_due_date" 
          placeholder={date|| "Select Date"} 
          label="Due date" minDate={new Date()}
          disabled={!editDues} />
        </Col>
       

        <Col lg={6} className = "mt-2">
          <Input type="text" id="penalty_due" label = "Penalty" inputIcon={<span>{currency}</span>}
            placeholder={data?.sales?.dues?.[0]?.penalty_due || "Penalty due"}
            disabled={!editDues} />
          </Col>

          <Col lg={6} className = "mt">
          <Input label="Rebate" type="text" id="rebate" inputIcon={<span>{currency}</span>}
           placeholder={data?.sales?.dues?.[0]?.rebate|| "Rebate"}
           disabled={!editDues} />
          </Col>

          {editDues ?
          <>
          <Input type="textarea" id="remarks" label="Remarks" placeholder="Type here..." maxLength={255}/>
          <div className="d-flex justify-content-between">
          <Button className="mt-4" color="cancel" type="button" onClick={() => toggleDues()}>
            Cancel
          </Button>
           <Button className="mt-4" type="button" color="primary" id="Update Dues" onClick={(e)=>handleSubmitTemp(e)}>
          Update Dues
          </Button>
          </div>
          </>
          :
          <Col lg={6} className = "mt">
           <Button className="mt-4" type="button" color="primary" id="Update Dues" onClick={()=>toggleDues()}>
          Edit
          </Button>
          </Col> }
    </FormGroup>
  );

  return (
    <>
      <Col lg={10} className="mt-1">
      {/* <ContactRecord data={data} editContact={editContact}/>
      </Col>
      <Col lg={10} className="px-4 pt-2">
      {editContact ? 
          <div className=" px-2 d-flex justify-content-between">
          <Button className="mt-4" color="cancel" type="button" onClick={() => toggleContact()}>
            Cancel
          </Button>
           <Button className="mt-4" type="button" color="primary" onClick={(e)=>handleSubmitTemp(e)}>
          Update
          </Button>
          </div>
          
          :
          <Col lg={6} className = "mt">
          <Button className="mt-2" type="button" color="primary" id="Update Dues" onClick={()=>toggleContact()}>
          Edit
          </Button>
          </Col> } */}
      
      {confirmationModal}
      </Col>
      {duesDetail}
      {/* <hr />
      <div className="pt-4">
          <div className="px-4 mb-3">
            <div style={{ color: '#2A2A3E' }}>Collection Specialist Id</div>
            <div>{data?.client_code}</div>
          </div>
          <div className="px-4 mb-1">
            <div style={{ color: '#2A2A3E' }}>Collection Specialist Name</div>
            <div>{data?.client_code!==0? data?.users?.first_name+" " +data?.users?.last_name : "Unassigned"}</div>
          </div>
          </div>
          <div className="pt-2 px-4">
            <Link to='/assignCs'>
                <Button className="px-4" color="primary">Edit</Button>
            </Link>
      </div>
      <hr/> */}
      <hr/>
      <div className="mt-3 px-4 mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button color="cancel" type="button" onClick={() => history.push('/')}>
              Cancel
            </Button>
            
      </div>
      {/* <div
        style={{
          position: 'fixed',
          right: 0,
          left: 0,
          bottom: 0,
          zIndex: 100,
          height: '80px',
          width: '100%',
          background: '#fff',
          padding: '15px 0',
        }}
      >
        <Container>
          <div className="d-flex justify-content-between">
            <Button color="cancel" type="button" onClick={() => history.push('/')}>
              Cancel
            </Button>
            <Button type="button" color="primary" disabled={isSubmitting} onClick={()=>setShowModal(true)}>
              Submit
            </Button>
            {confirmationModal}
          </div>
        </Container>
      </div> */}
    </>
  );
};

CustomerForm.propTypes = {
  data : PropTypes.any,
}

export default withRouter(CustomerForm);
